<template>

    <v-row >
        <v-col v-if="Window == 'pc'" class="bg-wms-register fill-height"> 
          <!-- ไปแก้css login ตรงที่ทำ -->
        </v-col>
      <v-col   cols="12" md="6" lg="4" align="center" >
        <v-card height="100%" >
          <v-card-text>
            <v-row>
              <v-col class="pt-5"   cols="12" align="left">
                <img src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/Oasys+500x100-01.png" width="100%" />
                <h1 class="fontcss " style="color: #424242;">&nbsp;&nbsp;&nbsp;&nbsp; ระบบจัดการคลังสินค้า</h1>
              </v-col>
            </v-row >
            <v-form ref="form" class="pt-5" v-model="valid" lazy-validation >
              <v-row>
                <v-col cols="12" class="pt-5">
                  <v-text-field
                    autofocus
                    v-model="username"
                    :rules="[(v) => !!v || 'กรุณากรอกชื่อ']"
                    required
                    ref="refusername"
                    label="ชื่อ"
                    outlined

                  ></v-text-field>
                </v-col>
                <v-col cols="12"  >
                  <v-text-field
                    v-model="phone"
                    :rules="[(v) => !!v || 'กรุณากรอกเบอร์โทรศัพท์']"
                    required
                    ref="refphone"
                    label="เบอร์โทร"
                    outlined
                    @keypress="isNumberWNoDot($event)"
                    maxlength="10"
                  ></v-text-field>
                </v-col>
                <v-col cols="12"  >
                  <v-text-field
                    v-model="email"
                    :rules="[(v) => !!v || 'กรุณากรอกอีเมล']"
                    required
                    ref="refemail"
                    label="อีเมล"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12"  >
                  <v-text-field
                    v-model="productType"
                    :rules="[(v) => !!v || 'กรุณากรอกประเภทธุรกิจ']"
                    required
                    ref="refProductType"
                    label="ประเภทธุรกิจ"
                    outlined
                  ></v-text-field>
                  
                </v-col>
                <v-col cols="12"  class="pt-10">
                  <v-btn
                   :style="{'background-color':warehouse_theme}"
                   :loading =loading
                    class="btn-login"
                    @click="RegisterWMS()"
                  >
                    <span class="fontcss">ลงทะเบียน</span>
                  </v-btn>
                  <v-col cols="12"  class="pt-10" >
                    <h3 class="fontcss" style="color: #424242;">*หมายเหตุ : จะมีเจ้าหน้าที่ติดต่อกลับไปภายใน 24 ชั่วโมง
                      <br /><br />หรือติดต่อด่วน ได้ที่ LINE : @oasys
                    </h3>
                </v-col>
                </v-col>

              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  
  </template>
  <style  scoped >
  
  .btn-login{
  font-size: 2em !important;
  width:50% !important;
  height:60px !important;
  color: #ffffff !important;
  background-image: linear-gradient(to right, #4fc3f7, #80deea , #a3e9a4);
  
  }
  .text-center {
  text-align: center; /* ใช้ text-align: center เพื่อจัดข้อความในตำแหน่งกลาง */
  }
  @import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');
  
  .fontcss {
    font-family: 'Prompt', sans-serif;
  }
  </style>
  <script>
  import axios from "axios";
  import Swal from 'sweetalert2'
  import { header_token } from "@/website/token";
  import { isNumberWNoDot } from '@/website/global_function'
  import {  branchService_dotnet } from "@/website/global";
  import { AlertSuccess, AlertWarning, AlertError } from "@/website/global_alert";
  export default {
  data: () => ({
  warehouse_theme:null,
  valid: true,
  loading: false,
  username: "",
  phone: "",
  email:"",
  productType:"",
  
      // size จอ
      size: "lg",
      window: {
        width: 0,
        height: 0,
      },
  
      // ขนาดจอ
      Window: "pc",
  }),
  async created() {
  
  window.addEventListener("resize", this.handleResize);
  this.handleResize();
  },
  watch: {
      size() {
        this.Window = this.size == "xs" || this.size == "sm" ? "mobile" : "pc";
      },
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },
  methods: {
  async RegisterWMS() {
    this.loading = true;
    if(this.valid) {
    let response = await axios.post(
      branchService_dotnet+"Customer/wms-register",
      {
        name:  this.username,
        phone: this.phone,
        email: this.email,
        productType: this.productType,
      },
      { headers: header_token }
    );
    if (response.data.status) {
      this.loading = false;
      Swal.fire({
          icon: 'success',
          title: 'ลงทะเบียนเรียบร้อย',
          text: 'กรุณารอเจ้าหน้าที่ติดต่อกลับ เพื่อแนะนำการใช้งานค่ะ',
          confirmButtonText: 'ตกลง',
          confirmButtonColor: '#3085d6',
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.value) {
            // this.username = "",
            // this.phone = "",
            // this.email = "",
            // this.productType=""
            window.location.reload();
           }
        })
      
    } else {
      this.loading = false;
      this.AlertError();
    }
  }
  else{
    this.loading = false;
    this.AlertWarning("กรุณากรอกข้อมูลให้ครบถ้วน");
  }
  },
  handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
        if (this.window.width < 600) {
          this.size = "xs";
        } else if (this.window.width >= 600 && this.window.width < 960) {
          this.size = "sm";
        } else if (this.window.width >= 960 && this.window.width < 1264) {
          this.size = "md";
        } else if (this.window.width >= 1264 && this.window.width < 1904) {
          this.size = "lg";
        } else if (this.window.width >= 1904) {
          this.size = "xl";
        }
      },
  isNumberWNoDot,
  AlertSuccess,
  AlertWarning,
  AlertError,
  },
  };
  </script>
  